@tailwind base;
@tailwind components;
@tailwind utilities;

label {
  display: block;
}

.leaflet-control-attribution {
  position: fixed;
  bottom: 0;
  right: 0;
}

::placeholder {
  @apply text-sm;
}
